import {
  S3Client,
  PutObjectCommand,
  GetObjectCommand,
} from "@aws-sdk/client-s3";

import { getCognitoCredentials } from ".";

export const getS3Client = async () => {
  const credentials = await getCognitoCredentials();

  const client = new S3Client({
    region: process.env.REACT_APP_region,
    credentials: credentials,
  });

  return client;
};

export const uploadToS3 = async ({
  fileName,
  fileBody,
  bucketName,
}: {
  fileName: string;
  fileBody: any;
  bucketName: string;
}) => {
  const client = await getS3Client();
  const command = new PutObjectCommand({
    Key: fileName,
    Body: fileBody,
    Bucket: bucketName,
  });
  try {
    await client.send(command);
    console.log("Upload Successful");
    return "Upload Successful";
  } catch (err) {
    console.log("Put S3 Object Err: ", err.message);
    return null;
  }
};

// Download file from S3
export const downloadFromS3 = async ({
  fileName,
  bucketName,
}: {
  fileName: string;
  bucketName: string;
}) => {
  const client = await getS3Client();

  const command = new GetObjectCommand({
    Key: fileName,
    Bucket: bucketName,
  });
  try {
    const response = await client.send(command);
    const reader = response.Body.transformToWebStream().getReader();

    let chunks = [];
    let result;
    let chunk;
    while (!(result = await reader.read()).done) {
      chunk = result?.value;
      chunks.push(chunk);
    }
    let file = new Blob(chunks);
    let url = URL.createObjectURL(file);
    let a = document.createElement("a");
    a.href = url;
    a.download = fileName;
    a.click();
    return response;
  } catch (err) {
    console.log("Get S3 Object Err: ", err.message);
    return null;
  }
};
