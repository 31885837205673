import axios from "axios";
import { addYears, format } from "date-fns";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import {
  refreshCognitoToken,
  trimStringEndWhiteSpace,
  getUserAccessToken,
} from "../utils";

type VehicleType = {
  _id: string;
  class: string;
  fuelType: string;
  licensePlate: string;
  licensePlateState: string;
  plateType: string;
  provider: string;
  vin: string;
  make: string;
  model: string;
  year: number;
  axles: number;
  tires: number;
  status: string;
  deviceType: string;
  tollTransponderId: string;
  fuelChargeId: string;
  obd2ID: string;
  equity: boolean;
  nickName: string;
  lastUpdatedBy: string;
  createDate: Date;
  lastUpdateDate: Date;
  licensePlateEndEffectiveDate: Date;
  licensePlateStartEffectiveDate: Date;
  vehicleStartEffectiveDate: Date;
  vehicleEndEffectiveDate: Date;
  subscriptions: [
    {
      service: string;
      startEffectivDate: Date;
      endEffectiveDate: Date;
      modifiedBy: string;
    }
  ];
  communicationPreferences: {
    receiveInfotainmentMessages: boolean;
    preferences: string[];
  };
};

type VehicleStatus = {
  _id: string;
  name: string;
};

export interface VehiclesState {
  initialLoad: boolean;
  isLoading: boolean;
  statusesLoading: boolean;
  hasError: boolean;
  vehiclesData: VehicleType[];
  rawVehiclesData: VehicleType[];
  vehicleStatusData: VehicleStatus[];
}

const initialState: VehiclesState = {
  initialLoad: true,
  isLoading: false,
  statusesLoading: false,
  hasError: false,
  vehiclesData: [],
  rawVehiclesData: [],
  vehicleStatusData: [],
};

export const getVehicleStatuses = createAsyncThunk(
  "vehicles/getVehicleStatuses",
  async () => {
    try {
      const accessToken = await getUserAccessToken();
      const response = await axios({
        url: `${process.env.REACT_APP_API_URL}/systemSettings/VehicleStatuses`,
        method: "GET",
        headers: {
          Authorization: accessToken,
        },
      });
      return response.data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  }
);

export const getAllVehicles = createAsyncThunk(
  "vehicles/getAllVehicles",
  async (params: any, thunkAPI) => {
    try {
      const accessToken = await getUserAccessToken();
      await refreshCognitoToken();
      const response = await axios({
        url: `${process.env.REACT_APP_API_URL}/vehicle`,
        method: "GET",
        headers: {
          Authorization: accessToken,
        },
      });
      return response.data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  }
);

export const getVehiclesByFilter = createAsyncThunk(
  "vehicles/getVehiclesByFilter",
  async (params: any, thunkAPI) => {
    try {
      const accessToken = await getUserAccessToken();
      await refreshCognitoToken();
      const response = await axios({
        url: `${process.env.REACT_APP_API_URL}/vehicle`,
        method: "POST",
        headers: {
          Authorization: accessToken,
        },
        data: params,
      });
      return response.data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  }
);

export const putVehicle = createAsyncThunk(
  "vehicles/putVehicle",
  async (params: any, thunkAPI) => {
    try {
      const accessToken = await getUserAccessToken();
      await refreshCognitoToken();
      const vehicle: any = {};
      Object.entries(params.editData).forEach(
        ([key, value]: [key: string, value: any]) =>
          (vehicle[key] = value.value)
      );
      // Handle trimming white space off of text input fields
      vehicle["nickName"] = trimStringEndWhiteSpace(vehicle["nickName"]);
      vehicle["vin"] = trimStringEndWhiteSpace(vehicle["vin"]);
      vehicle["licensePlate"] = trimStringEndWhiteSpace(
        vehicle["licensePlate"]
      );
      vehicle["plateType"] = trimStringEndWhiteSpace(vehicle["plateType"]);
      vehicle["provider"] = trimStringEndWhiteSpace(vehicle["provider"]);
      vehicle["make"] = trimStringEndWhiteSpace(vehicle["make"]);
      vehicle["model"] = trimStringEndWhiteSpace(vehicle["model"]);
      vehicle["tollTransponderId"] = trimStringEndWhiteSpace(
        vehicle["tollTransponderId"]
      );
      vehicle["fuelChargeId"] = trimStringEndWhiteSpace(
        vehicle["fuelChargeId"]
      );
      vehicle["obd2ID"] = trimStringEndWhiteSpace(vehicle["obd2ID"]);
      vehicle["communicationPreferences"] = {
        receiveInfotainmentMessages:
          params.editData?.communicationPreferences?.value
            ?.receiveInfotainmentMessages,
        preferences: [],
      };
      vehicle["vehicleEndEffectiveDate"] = new Date(
        vehicle["vehicleEndEffectiveDate"]
      ).toISOString();

      // Do not send lastUpdateDate or createDate or lastUpdatedBy
      delete vehicle.lastUpdateDate;
      delete vehicle.createDate;
      delete vehicle.lastUpdatedBy;

      const response = await axios({
        url: `${process.env.REACT_APP_API_URL}/vehicle/${params.editData._id.value}`,
        method: "PUT",
        headers: {
          Authorization: accessToken,
        },
        data: vehicle,
      });
      return response.data;
    } catch (err) {
      console.log("Put Vehicle Err: ", err.message);
      throw err;
    }
  }
);

export const vehiclesSlice = createSlice({
  name: "vehiclesSlice",
  initialState,
  reducers: {
    insertClassNames: (state, action) => {
      state.vehiclesData = state.vehiclesData?.map((vehicle: any) => {
        return {
          ...vehicle,
          className:
            action.payload.classTypes.find(
              (classType: any) => classType._id === vehicle?.class
            )?.name || "",
        };
      });
      state.rawVehiclesData = state.rawVehiclesData?.map((vehicle: any) => {
        return {
          ...vehicle,
          className:
            action.payload.classTypes.find(
              (classType: any) => classType._id === vehicle?.class
            )?.name || "",
        };
      });
    },
    insertStatusNames: (state, action) => {
      state.vehiclesData = state.vehiclesData?.map((vehicle: any) => {
        return {
          ...vehicle,
          statusName:
            action.payload.vehicleStatuses.find(
              (status: any) => status._id === vehicle?.status
            )?.name || "",
        };
      });
      state.rawVehiclesData = state.rawVehiclesData?.map((vehicle: any) => {
        return {
          ...vehicle,
          statusName:
            action.payload.vehicleStatuses.find(
              (status: any) => status._id === vehicle?.status
            )?.name || "",
        };
      });
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getVehicleStatuses.pending, (state, action) => {
        state.statusesLoading = true;
        state.hasError = false;
      })
      .addCase(getVehicleStatuses.fulfilled, (state, action) => {
        state.statusesLoading = false;
        state.hasError = false;
        state.vehicleStatusData = action?.payload?.data
          ?.map((e: any) => ({
            ...e,
            label: e.name,
            value: e._id,
          }))
          .sort((a: any, b: any) =>
            a.name?.toLowerCase()?.localeCompare(b.name?.toLowerCase())
          );
      })
      .addCase(getVehicleStatuses.rejected, (state, action) => {
        state.hasError = true;
        state.statusesLoading = false;
      })
      // GET
      .addCase(getAllVehicles.pending, (state, action) => {
        state.initialLoad = true;
        state.isLoading = true;
        state.hasError = false;
      })
      .addCase(getAllVehicles.fulfilled, (state, action) => {
        state.vehiclesData = action.payload.map((vehicle: any) => {
          return {
            ...vehicle,
            vehicleEndEffectiveDate: vehicle?.vehicleEndEffectiveDate
              ? vehicle?.vehicleEndEffectiveDate.split("T")[0]
              : format(addYears(new Date(), 5), "yyyy-MM-dd"),
          };
        });
        state.rawVehiclesData = action.payload.map((vehicle: any) => {
          return {
            ...vehicle,
            vehicleEndEffectiveDate: vehicle?.vehicleEndEffectiveDate
              ? vehicle?.vehicleEndEffectiveDate.split("T")[0]
              : format(addYears(new Date(), 5), "yyyy-MM-dd"),
          };
        });
        state.initialLoad = false;
        state.isLoading = false;
        state.hasError = false;
      })
      .addCase(getAllVehicles.rejected, (state, action) => {
        state.hasError = true;
        state.initialLoad = false;
        state.isLoading = false;
      })
      // GET BY FILTERS
      .addCase(getVehiclesByFilter.pending, (state, action) => {
        state.isLoading = true;
        state.hasError = false;
      })
      .addCase(getVehiclesByFilter.fulfilled, (state, action) => {
        state.vehiclesData = action.payload;
        state.isLoading = false;
        state.hasError = false;
      })
      .addCase(getVehiclesByFilter.rejected, (state, action) => {
        state.hasError = true;
        state.isLoading = false;
      })
      // PUT
      .addCase(putVehicle.pending, (state, action) => {
        state.isLoading = true;
        state.hasError = false;
      })
      .addCase(putVehicle.fulfilled, (state, action) => {
        const updatedVehicleIndex = state.vehiclesData.findIndex(
          (vehicle: any) => vehicle._id === action.payload._id
        );

        if (updatedVehicleIndex === -1) {
          state.vehiclesData.push({
            ...action.payload,
            vehicleEndEffectiveDate: action.payload?.vehicleEndEffectiveDate
              ? action.payload?.vehicleEndEffectiveDate.split("T")[0]
              : format(addYears(new Date(), 5), "yyyy-MM-dd"),
          });
          state.rawVehiclesData.push({
            ...action.payload,
            vehicleEndEffectiveDate: action.payload?.vehicleEndEffectiveDate
              ? action.payload?.vehicleEndEffectiveDate.split("T")[0]
              : format(addYears(new Date(), 5), "yyyy-MM-dd"),
          });
        } else {
          state.vehiclesData[updatedVehicleIndex] = {
            ...action.payload,
            vehicleEndEffectiveDate: action.payload?.vehicleEndEffectiveDate
              ? action.payload?.vehicleEndEffectiveDate.split("T")[0]
              : format(addYears(new Date(), 5), "yyyy-MM-dd"),
          };
          state.rawVehiclesData[updatedVehicleIndex] = {
            ...action.payload,
            vehicleEndEffectiveDate: action.payload?.vehicleEndEffectiveDate
              ? action.payload?.vehicleEndEffectiveDate.split("T")[0]
              : format(addYears(new Date(), 5), "yyyy-MM-dd"),
          };
        }
        state.isLoading = false;
        state.hasError = false;
      })
      .addCase(putVehicle.rejected, (state, action) => {
        state.hasError = true;
        state.isLoading = false;
      });
  },
});

export const { insertClassNames, insertStatusNames } = vehiclesSlice.actions;

export default vehiclesSlice.reducer;
