import axios from "axios";

import { getUserAccessToken, refreshCognitoToken } from "../../utils";

export const getMessagesAPI = async () => {
  try {
    const accessToken = await getUserAccessToken();
    await refreshCognitoToken();
    const res = await axios({
      url: `${process.env.REACT_APP_API_URL}/messages`,
      method: "GET",
      headers: {
        Authorization: accessToken,
      },
    });
    return res.data;
  } catch (err) {
    console.log("Get Messages Error: ", err.message);
    throw err;
  }
};

export const putMessageAPI = async (message: any) => {
  try {
    const accessToken = await getUserAccessToken();
    await refreshCognitoToken();
    let updatedMessage: any = {};
    Object.entries(message).forEach(
      ([key, value]: [key: string, value: any]) =>
        (updatedMessage[key] = value.value)
    );
    const response = await axios({
      url: `${process.env.REACT_APP_API_URL}/messages/${updatedMessage._id}`,
      method: "PUT",
      headers: {
        Authorization: accessToken,
      },
      data: updatedMessage,
    });
    return response.data;
  } catch (err) {
    console.log("Put Message Error: ", err.message);
    throw err;
  }
};
