import {
  ConnectOneModal,
  ConnectOneButton,
  ConnectOneDropdown,
  ConnectOneInput,
} from "../../../components";

import "./Messages.scss";

export const MessagesAdvancedSearch = ({
  searchData,
  isLoading,
  handleChange,
  handleSubmit,
  handleClose,
}: // businessPartnersList,
{
  searchData?: any;
  isLoading?: boolean;
  handleChange?: any;
  handleSubmit?: any;
  handleClose?: any;
  // businessPartnersList?: any;
}) => {
  return (
    <ConnectOneModal title={"Advanced Search"} handleClose={handleClose}>
      <div className="advanced-search-container">
        <h2>Message Data</h2>
        <div className="inputs-container">
          <ConnectOneInput
            handleChange={handleChange}
            label={"Name"}
            type={"text"}
            name={"name"}
            placeholder={"Message Name"}
            value={searchData.name.value}
          />
          <ConnectOneDropdown
            handleChange={handleChange}
            label={"Provider"}
            name={"provider"}
            options={[
              { label: "Any", value: "" },
              ...searchData.provider.options,
            ]}
            value={searchData.provider.value || ""}
          />
          {/* <ConnectOneDropdown
            handleChange={handleChange}
            label={"Business Partner"}
            name={"businessPartner"}
            options={[
              { label: "Any", value: "" },
              ...businessPartnersList.options.map((partner: any) => ({
                label: partner.label,
                value: partner.value,
              })),
            ]}
            value={searchData.businessPartner?.value || ""}
          /> */}
          {/* Type */}
          <ConnectOneDropdown
            handleChange={handleChange}
            label={"Type"}
            name={"type"}
            options={[{ label: "Any", value: "" }, ...searchData.type.options]}
            value={searchData.type.value}
          />
        </div>

        <div className="advanced-search-submit">
          <ConnectOneButton
            label={"Search"}
            handleClick={handleSubmit}
            disabled={false}
            isLoading={isLoading}
          />
        </div>
      </div>
    </ConnectOneModal>
  );
};
