import axios from "axios";

import { getUserAccessToken, refreshCognitoToken } from "../../utils";

export const getRateAPI = async (params: any) => {
  try {
    const accessToken = await getUserAccessToken();
    await refreshCognitoToken();
    const res = await axios({
      url: `${process.env.REACT_APP_API_URL}/rateReport`,
      method: "POST",
      headers: {
        Authorization: accessToken,
      },
      data: params,
    });
    return res.data;
  } catch (err) {
    console.log("Rate Report Error: ", err.message);
    throw err;
  }
};

export const getRateAdvancedAPI = async (params: any) => {
  try {
    const accessToken = await getUserAccessToken();
    await refreshCognitoToken();
    const res = await axios({
      url: `${process.env.REACT_APP_API_URL}/rateReport/advanced`,
      method: "POST",
      headers: {
        Authorization: accessToken,
      },
      data: params,
    });
    return res.data;
  } catch (err) {
    console.log("Rate Report Error: ", err.message);
    throw err;
  }
};
