import axios from "axios";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import { getUserAccessToken } from "../utils";

type FuelType = {
  label: string;
  value: string;
  name: string;
};
export interface FuelTypeState {
  isLoading: boolean;
  hasError: boolean;
  fuelTypesData: FuelType[];
}

const initialState: FuelTypeState = {
  isLoading: false,
  hasError: false,
  fuelTypesData: [],
};

export const getFuelTypes = createAsyncThunk(
  "fuelTypes/getFuelTypes",
  async () => {
    try {
      const accessToken = await getUserAccessToken();
      const response = await axios({
        url: `${process.env.REACT_APP_API_URL}/systemSettings/FuelTypes`,
        method: "GET",
        headers: {
          Authorization: accessToken,
        },
      });
      return response.data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  }
);

export const fuelTypesSlice = createSlice({
  name: "fuelTypesSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getFuelTypes.pending, (state, action) => {
        state.isLoading = true;
        state.hasError = false;
      })
      .addCase(getFuelTypes.fulfilled, (state, action) => {
        state.fuelTypesData = action?.payload?.data
          ?.map((e: any) => ({
            ...e,
            label: e.name,
            value: e.name,
          }))
          .sort((a: any, b: any) =>
            a.name?.toLowerCase()?.localeCompare(b.name?.toLowerCase())
          );
        state.isLoading = false;
        state.hasError = false;
      })
      .addCase(getFuelTypes.rejected, (state, action) => {
        state.hasError = true;
        state.isLoading = false;
      });
  },
});

export default fuelTypesSlice.reducer;
