import axios from "axios";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import {
  refreshCognitoToken,
  trimStringEndWhiteSpace,
  getUserAccessToken,
} from "../utils";

type Service = {
  label: string;
  value: string;
  name: string;
  _id: string;
};

type ServiceStatus = {
  label: string;
  value: boolean | string;
};

export interface ServicesState {
  initialLoad: boolean;
  isLoading: boolean;
  hasError: boolean;
  servicesData: Service[];
  serviceStatusData: ServiceStatus[];
}

const initialState: ServicesState = {
  initialLoad: true,
  isLoading: false,
  hasError: false,
  servicesData: [],
  serviceStatusData: [],
};

export const getServiceStatuses = createAsyncThunk(
  "services/getServiceStatuses",
  async () => {
    try {
      const accessToken = await getUserAccessToken();
      await refreshCognitoToken();
      const response = await axios({
        url: `${process.env.REACT_APP_API_URL}/systemSettings/ServiceStatuses`,
        method: "GET",
        headers: {
          Authorization: accessToken,
        },
      });
      return response.data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  }
);

export const getServices = createAsyncThunk(
  "services/getServices",
  async () => {
    try {
      const accessToken = await getUserAccessToken();
      await refreshCognitoToken();
      const response = await axios({
        url: `${process.env.REACT_APP_API_URL}/services`,
        method: "GET",
        headers: {
          Authorization: accessToken,
        },
      });
      return response.data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  }
);

export const putService = createAsyncThunk(
  "services/putService",
  async (params: any, thunkAPI) => {
    try {
      const accessToken = await getUserAccessToken();
      await refreshCognitoToken();
      const serviceType: any = {};
      Object.entries(params.editData).forEach(
        ([key, value]: [key: string, value: any]) =>
          (serviceType[key] = value.value)
      );
      serviceType["name"] = trimStringEndWhiteSpace(serviceType["name"]);

      // Do not send lastupdateDate or modifiedBy or createdDate
      delete serviceType["lastupdateDate"];
      delete serviceType["modifiedBy"];
      delete serviceType["createdDate"];

      const response = await axios({
        url: `${process.env.REACT_APP_API_URL}/services/${params.editData?._id?.value}`,
        method: "PUT",
        headers: {
          Authorization: accessToken,
        },
        data: serviceType,
      });

      return response.data;
    } catch (err) {
      console.log("Put Service Type Err: ", err.message);
      throw err;
    }
  }
);

export const servicesSlice = createSlice({
  name: "servicesSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      // GET Service Statuses
      .addCase(getServiceStatuses.pending, (state, action) => {
        state.isLoading = true;
        state.hasError = false;
      })
      .addCase(getServiceStatuses.fulfilled, (state, action) => {
        state.isLoading = false;
        state.hasError = false;
        state.serviceStatusData = action?.payload?.data
          ?.map((e: any) => ({
            ...e,
            label: e.name,
            value: e.value,
          }))
          .sort((a: any, b: any) =>
            a.name?.toLowerCase()?.localeCompare(b.name?.toLowerCase())
          );
      })
      .addCase(getServiceStatuses.rejected, (state, action) => {
        state.hasError = true;
        state.isLoading = false;
      })
      // GET Services
      .addCase(getServices.pending, (state, action) => {
        state.initialLoad = true;
        state.isLoading = true;
        state.hasError = false;
      })
      .addCase(getServices.fulfilled, (state, action) => {
        state.servicesData = action?.payload
          ?.map((e: any) => ({
            ...e,
            label: e.name,
            value: e.name,
          }))
          .sort((a: any, b: any) =>
            a.name?.toLowerCase()?.localeCompare(b.name?.toLowerCase())
          );
        state.initialLoad = false;
        state.isLoading = false;
        state.hasError = false;
      })
      .addCase(getServices.rejected, (state, action) => {
        state.hasError = true;
        state.initialLoad = false;
        state.isLoading = false;
      })
      // PUT
      .addCase(putService.pending, (state, action) => {
        state.isLoading = true;
        state.hasError = false;
      })
      .addCase(putService.fulfilled, (state, action) => {
        const updatedServiceTypeIndex = state.servicesData.findIndex(
          (serviceType: any) => serviceType._id === action.payload._id
        );

        if (updatedServiceTypeIndex === -1) {
          state.servicesData.push({
            ...action?.payload,
            label: action?.payload?.name,
            value: action?.payload?.name,
          });
        } else {
          state.servicesData[updatedServiceTypeIndex] = {
            ...action?.payload,
            label: action?.payload?.name,
            value: action?.payload?.name,
          };
        }

        state.isLoading = false;
        state.hasError = false;
      })
      .addCase(putService.rejected, (state, action) => {
        state.hasError = true;
        state.isLoading = false;
      });
  },
});

export default servicesSlice.reducer;
