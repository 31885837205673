import axios from "axios";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import {
  geofenceToGeoJSON,
  geofencesToGeoJSON,
  refreshCognitoToken,
  sortGridData,
  trimStringEndWhiteSpace,
  getUserAccessToken,
} from "../utils";

type GeofenceData = {
  type: string;
  features: any[];
};

export interface GeofenceState {
  initialLoad: boolean;
  isLoading: boolean;
  hasError: boolean;
  geofencesData: GeofenceData; // Mutable
  rawGeofenceData: GeofenceData; // Immutable
}

const initialState: GeofenceState = {
  initialLoad: true,
  isLoading: false,
  hasError: false,
  geofencesData: { type: "FeatureCollection", features: [] } as GeofenceData,
  rawGeofenceData: { type: "FeatureCollection", features: [] } as GeofenceData,
};

// Getting all locations in chunks to ease data load
export const getGeofences = createAsyncThunk(
  "geofences/getGeofences",
  async () => {
    try {
      const accessToken = await getUserAccessToken();
      await refreshCognitoToken();
      // Get count of all geofences in db
      const geofenceCount = await axios({
        url: `${process.env.REACT_APP_API_URL}/geofence/count`,
        method: "GET",
        headers: {
          Authorization: accessToken,
        },
      });

      const totalCount = geofenceCount.data;
      const chunkSize = 500;
      const totalRequests = Math.ceil(totalCount / chunkSize);

      // Store all data in array and return after all chunks are fetched
      const geofences: any[] = [];
      const fetchPromises = [];
      // Skip in mongo expects 1 as the first page
      for (let page = 1; page < totalRequests + 1; page++) {
        const promise = axios({
          url: `${process.env.REACT_APP_API_URL}/geofence/chunk/${page}/${chunkSize}`,
          method: "GET",
          headers: {
            Authorization: accessToken,
          },
        });
        fetchPromises.push(promise);
      }

      // Call all requests in parallel
      const responses = await Promise.all(fetchPromises);
      for (const response of responses) {
        geofences.push(...response.data);
      }

      return geofences;
    } catch (error) {
      console.log(error);
      throw error;
    }
  }
);

export const getGeofenceById = createAsyncThunk(
  "geofences/getGeofenceById",
  async (geofenceId: any, thunkAPI) => {
    try {
      const accessToken = await getUserAccessToken();
      await refreshCognitoToken();
      const response = await axios({
        url: `${process.env.REACT_APP_API_URL}/geofence/${geofenceId}`,
        method: "GET",
        headers: {
          Authorization: accessToken,
        },
      });
      return response.data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  }
);

export const putGeofence = createAsyncThunk(
  "geofences/putGeofence",
  async (geofence: any, thunkAPI) => {
    try {
      const accessToken = await getUserAccessToken();
      await refreshCognitoToken();
      const {
        name,
        agencyId,
        plazaId,
        locationType,
        locationSubType,
        schedules,
        eventActions,
        lockStatus,
        notes,
        verified,
        course,
        roadName,
        status,
      } = geofence.properties;

      const response = await axios({
        url: `${process.env.REACT_APP_API_URL}/geofence/${geofence?.id}`,
        method: "PUT",
        headers: {
          Authorization: accessToken,
        },
        data: {
          _id: geofence?.id,
          name: trimStringEndWhiteSpace(name),
          geometry: geofence?.geometry,
          properties: {
            ...geofence.properties,
            agencyId,
            lockStatus,
            locationType,
            notes,
            verified,
            locationSubType,
            course,
            plazaId: trimStringEndWhiteSpace(plazaId),
            roadName: trimStringEndWhiteSpace(roadName),
            status,
            // Not sending over full schedules and eventActions to save space
            schedules: [],
            eventActions: [],
          },
          eventActions: eventActions,
          schedules: schedules,
        },
      });

      return response.data;
    } catch (err) {
      console.log("Put Geofence Err: ", err.message);
      throw new Error(`Put Geofence Err: ${err.message}`);
    }
  }
);

export const deleteGeofence = createAsyncThunk(
  "geofences/deleteGeofence",
  async (geofenceId: any, thunkAPI) => {
    try {
      const accessToken = await getUserAccessToken();
      await refreshCognitoToken();
      const response = await axios({
        url: `${process.env.REACT_APP_API_URL}/geofence/${geofenceId}`,
        method: "DELETE",
        headers: {
          Authorization: accessToken,
        },
      });
      return response.data;
    } catch (err) {
      console.log("Del Geofence Err: ", err.message);
      throw new Error(`Del Geofence Err: ${err.message}`);
    }
  }
);

export const geofenceSlice = createSlice({
  name: "geofenceSlice",
  initialState,
  reducers: {
    toggleGeofenceView: (state, action) => {
      state.geofencesData = {
        type: state.geofencesData.type,
        features: state.rawGeofenceData?.features?.filter((geofence: any) =>
          action.payload.includes(geofence.properties.locationType)
        ),
      };
    },
    handleGeofenceFilter: (state, action) => {
      state.geofencesData = {
        type: state.geofencesData.type,
        features: [...state.rawGeofenceData?.features]?.filter(
          (geofence: any) => {
            const {
              locationId,
              locationType,
              name,
              agencyId,
              state,
              verified,
              status,
              plazaId,
            } = action.payload;

            return (
              (locationId === undefined || geofence._id === locationId) &&
              (agencyId === undefined || geofence.agencyId === agencyId) &&
              (plazaId === undefined ||
                `${geofence?.plazaId}`
                  .toLowerCase()
                  .includes(plazaId.toLowerCase())) &&
              (locationType === undefined ||
                geofence.locationType === locationType) &&
              (name === undefined ||
                geofence.name.toLowerCase().includes(name.toLowerCase())) &&
              (state === undefined || geofence.agencyState === state) &&
              (status === undefined || geofence.status === status) &&
              (verified === undefined ||
                geofence.verified === (verified === "true" ? true : false))
            );
          }
        ),
      };
    },
    handleGeofenceSort: (state, action) => {
      const { sortedValue, sortAscending } = action.payload;
      const sortedGeofences = sortGridData({
        unsortedData: state.geofencesData.features,
        sortValue: sortedValue,
        ascending: sortAscending,
      });

      state.geofencesData = {
        type: state.geofencesData.type,
        features: sortedGeofences,
      };
    },
    insertAgencyNames: (state, action) => {
      const agencyMap = new Map(
        action.payload.agencies.map((agency: any) => [agency._id, agency])
      );

      state.geofencesData = {
        type: state.geofencesData.type,
        features: state.geofencesData.features.map((geofence: any) => {
          const agency = agencyMap.get(geofence?.properties?.agencyId) as any;

          return {
            ...geofence,
            agencyName: agency?.name || "",
            agencyState: agency?.state || "",
          };
        }),
      };
      state.rawGeofenceData = {
        type: state.rawGeofenceData.type,
        features: state.rawGeofenceData.features.map((geofence: any) => {
          const agency = agencyMap.get(geofence?.properties?.agencyId) as any;

          return {
            ...geofence,
            agencyName: agency?.name || "",
            agencyState: agency?.state || "",
          };
        }),
      };
    },
    insertLocationStatusNames: (state, action) => {
      const statusMap = new Map(
        action.payload.locationStatuses?.map((status: any) => [
          status._id,
          status,
        ])
      );

      state.geofencesData = {
        type: state.geofencesData.type,
        features: state.geofencesData?.features?.map((geofence: any) => {
          const status = statusMap.get(geofence?.properties?.status) as any;

          return {
            ...geofence,
            statusName: status?.name || "",
          };
        }),
      };
      state.rawGeofenceData = {
        type: state.rawGeofenceData.type,
        features: state.rawGeofenceData?.features?.map((geofence: any) => {
          const status = statusMap.get(geofence?.properties?.status) as any;

          return {
            ...geofence,
            statusName: status?.name || "",
          };
        }),
      };
    },
    insertTempGeofence: (state, action) => {
      const geofenceAsGeoJSON = geofenceToGeoJSON(action.payload);
      state.rawGeofenceData.features.push({
        ...geofenceAsGeoJSON,
        isTemp: true,
      });
      state.geofencesData.features.push({ ...geofenceAsGeoJSON, isTemp: true });
    },
    removeTempGeofence: (state, action) => {
      state.rawGeofenceData.features = state.rawGeofenceData.features.filter(
        (geofence: any) => !geofence.isTemp
      );
      state.geofencesData.features = state.geofencesData.features.filter(
        (geofence: any) => !geofence.isTemp
      );
    },
  },
  extraReducers: (builder) => {
    builder
      // GET ALL
      .addCase(getGeofences.pending, (state, action) => {
        state.initialLoad = true;
        state.isLoading = true;
        state.hasError = false;
      })
      .addCase(getGeofences.fulfilled, (state, action) => {
        const geofencesAsGeoJSON = geofencesToGeoJSON(action.payload);
        state.rawGeofenceData = geofencesAsGeoJSON;
        state.geofencesData = geofencesAsGeoJSON;
        state.initialLoad = false;
        state.isLoading = false;
        state.hasError = false;
      })
      .addCase(getGeofences.rejected, (state, action) => {
        state.hasError = true;
        state.isLoading = false;
        state.initialLoad = false;
      })
      // GET SINGLE
      .addCase(getGeofenceById.pending, (state, action) => {
        state.isLoading = true;
        state.hasError = false;
      })
      .addCase(getGeofenceById.fulfilled, (state, action) => {
        if (action.payload.length === 0) return;

        const geofenceAsGeoJSON = geofenceToGeoJSON(action.payload[0]);
        // Update geofence with any extra details
        const updatedRawGeofenceIndex =
          state.rawGeofenceData.features.findIndex(
            (geofence: any) => geofence._id === geofenceAsGeoJSON.id
          );
        if (updatedRawGeofenceIndex > -1) {
          state.rawGeofenceData.features[updatedRawGeofenceIndex] = {
            ...state.rawGeofenceData.features[updatedRawGeofenceIndex],
            ...geofenceAsGeoJSON,
          };
        }

        const updatedGeofenceIndex = state.geofencesData.features.findIndex(
          (geofence: any) => geofence._id === geofenceAsGeoJSON.id
        );
        if (updatedRawGeofenceIndex > -1) {
          state.geofencesData.features[updatedGeofenceIndex] = {
            ...state.geofencesData.features[updatedGeofenceIndex],
            ...geofenceAsGeoJSON,
          };
        }

        state.isLoading = false;
        state.hasError = false;
      })
      .addCase(getGeofenceById.rejected, (state, action) => {
        state.hasError = true;
        state.isLoading = false;
      })
      // PUT
      .addCase(putGeofence.pending, (state, action) => {
        state.isLoading = true;
        state.hasError = false;
      })
      .addCase(putGeofence.fulfilled, (state, action) => {
        const geofenceAsGeoJSON = geofenceToGeoJSON(action.payload);
        // Insert or update geofence data
        const updatedRawGeofenceIndex =
          state.rawGeofenceData.features.findIndex(
            (geofence: any) => geofence._id === action.payload._id
          );

        if (updatedRawGeofenceIndex === -1) {
          state.rawGeofenceData.features.push(geofenceAsGeoJSON);
        } else {
          state.rawGeofenceData.features[updatedRawGeofenceIndex] =
            geofenceAsGeoJSON;
        }

        const updatedGeofenceIndex = state.geofencesData.features.findIndex(
          (geofence: any) => geofence._id === action.payload._id
        );
        if (updatedGeofenceIndex === -1) {
          state.geofencesData.features.push(geofenceAsGeoJSON);
        } else {
          state.geofencesData.features[updatedGeofenceIndex] =
            geofenceAsGeoJSON;
        }

        // Remove any temp geofences
        state.rawGeofenceData.features = state.rawGeofenceData.features.filter(
          (geofence: any) => !geofence.isTemp
        );
        state.geofencesData.features = state.geofencesData.features.filter(
          (geofence: any) => !geofence.isTemp
        );

        state.isLoading = false;
        state.hasError = false;
      })
      .addCase(putGeofence.rejected, (state, action) => {
        state.hasError = true;
        state.isLoading = false;
      })
      // DELETE
      .addCase(deleteGeofence.pending, (state, action) => {
        state.isLoading = true;
        state.hasError = false;
      })
      .addCase(deleteGeofence.fulfilled, (state, action) => {
        if (!action.payload._id) {
          console.log("No id found");
          return;
        }

        const updatedRawGeofenceIndex =
          state.rawGeofenceData.features.findIndex(
            (geofence: any) => geofence._id === action.payload._id
          );
        state.rawGeofenceData.features.splice(updatedRawGeofenceIndex, 1);

        const updatedGeofenceIndex = state.geofencesData.features.findIndex(
          (geofence: any) => geofence._id === action.payload._id
        );
        state.geofencesData.features.splice(updatedGeofenceIndex, 1);
        state.isLoading = false;
        state.hasError = false;
      })
      .addCase(deleteGeofence.rejected, (state, action) => {
        state.hasError = true;
        state.isLoading = false;
      });
  },
});

export const {
  toggleGeofenceView,
  handleGeofenceSort,
  insertAgencyNames,
  insertLocationStatusNames,
  handleGeofenceFilter,
  insertTempGeofence,
  removeTempGeofence,
} = geofenceSlice.actions;

export default geofenceSlice.reducer;
