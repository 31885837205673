import { useEffect, useState } from "react";

import { sortGridData } from "../utils";
import { getMessagesAPI, putMessageAPI } from "../axios";

export const useMessageManagement = ({
  user,
  filterState = {},
}: {
  user: any;
  filterState?: any;
}) => {
  const [userSearched, setUserSearch] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [initialLoad, setInitialLoad] = useState(true);
  const [isViewing, setIsViewing] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [isSearching, setIsSearching] = useState(false);
  const [sortedValue, setSortedValue] = useState("");
  const [sortAscending, setSortAscending] = useState(true);
  const [autoCompleteValue, setAutoCompleteValue] = useState("");
  const [availableProviders, setAvailableProviders] = useState<any>([]);

  const [searchData, setSearchData] = useState<any>(filterState);

  const [initialResponseData, setInitialResponseData] = useState<any>(null);
  const [responseData, setResponseData] = useState<any>(null);
  const [editData, setEditData] = useState<any>({});

  const displayingFilteredData = Object.values(searchData).some(
    (value: any) => !!value.value
  );

  const handleResetSearch = () => {
    setSearchData(filterState);
  };

  const messageTypes = [
    { value: "alert", label: "Alert" },
    { value: "caution", label: "Caution" },
    { value: "info", label: "Information" },
  ];

  // Table Headers
  const messageTableHeaders = [
    {
      name: "Provider",
      value: "provider",
      helpText: "The provider of the message",
    },
    // {
    //   name: "Business Partner",
    //   value: "businessPartner",
    //   helpText: "The business partner: Car Rental Agency",
    // },
    {
      name: "Name",
      value: "displayName",
      helpText: "Name of the message",
    },
    {
      name: "Message",
      value: "messageBody",
      helpText: "Message that will be displayed in vehicle",
    },
    {
      name: "Auto Close",
      value: "autoClose",
      helpText: "Time it will take for message to auto close",
    },
    {
      name: "Type",
      value: "messageTypeString",
      helpText: "The type of the message: information, error, warning",
    },
    {
      name: "Updated By",
      value: "updatedBy",
      helpText: "The user who last updated the message",
    },
    {
      name: "Last Updated Date",
      value: "updatedTimestampUTC",
      helpText: "The date when the message was last updated",
    },
  ];

  // Fetch all messages
  useEffect(() => {
    const fetchData = async () => {
      let response = await getMessagesAPI();

      // Set message type to display name
      response.forEach((message: any) => {
        const messageType = messageTypes.find(
          (type) => type.value === message.messageType
        );
        message.messageTypeString = messageType?.label
          ? messageType.label
          : message.messageType;
      });

      setInitialResponseData(response);
      setResponseData(response);
      setInitialLoad(false);
      setIsViewing(true);
    };
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Set available providers when responseData initially loads
  if (!availableProviders.length && responseData?.length) {
    const providers = Array.from(
      new Set(responseData?.map((message: any) => message?.provider))
    );
    setAvailableProviders(providers);
  }

  // AutoComplete Logic
  const autoCompleteOptions = availableProviders
    .map((item: any, key: any) => ({
      label: item || "",
    }))
    .sort((a: any, b: any) => a.label.localeCompare(b.label));

  // Filter responseData based on AutoComplete selection
  const onAutoCompleteSelect = (option: any) => {
    const { label } = option;
    setAutoCompleteValue(label);
  };

  const onAutoCompleteSearch = () => {
    setUserSearch(true);
    const filteredData = initialResponseData?.filter(
      (message: any) => message.provider === autoCompleteValue
    );
    setResponseData(filteredData);
  };

  // Sort Logic
  const handleSorting = (header: string) => {
    if (sortedValue === header && !sortAscending) {
      const sortedResponseData = sortGridData({
        unsortedData: responseData,
        sortValue: header,
        ascending: false,
      });
      setSortAscending(true);
      setResponseData(sortedResponseData);
      return;
    }
    const sortedResponseData = sortGridData({
      unsortedData: responseData,
      sortValue: header,
      ascending: true,
    });
    setSortedValue(header);
    setSortAscending(false);
    setResponseData(sortedResponseData);
    return;
  };

  // Row Selection Logic
  const handleRowSelection = (row: any) => {
    Object.entries(row).forEach(([key, value]: [key: string, value: any]) =>
      setEditData((prevEditData: any) => {
        if (key === "name") {
          return {
            ...prevEditData,
            // @ts-ignore
            [key]: { ...editData[key], value: value },
            prevName: { ...editData[key], value: value },
          };
        }

        return {
          ...prevEditData,
          // @ts-ignore
          [key]: { ...editData[key], value: value },
        };
      })
    );
    setIsEditing(true);
  };

  // Edit Form Logic
  const handleGoBack = () => {
    setIsEditing(false);
    setEditData({} as any);
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setEditData((prevEditData: any) => ({
      ...prevEditData,
      [name]: { ...prevEditData[name], value: value },
    }));
  };

  const handleSubmit = async () => {
    setIsLoading(true);
    const response = await putMessageAPI(editData);
    setIsLoading(false);
    setIsEditing(false);
    setEditData({} as any);

    // Update response data with new data by _id
    const updatedResponseData = responseData.map((message: any) => {
      if (message._id === response._id) {
        return {
          ...response,
          messageTypeString: messageTypes.find(
            (type) => type.value === response.messageType
          )?.label,
        };
      }
      return message;
    });
    setResponseData(updatedResponseData);
  };

  const handleFilterInputChange = (e: any) => {
    const { name, value } = e.target;

    setSearchData((prevSearchData: any) => {
      return {
        ...prevSearchData,
        // @ts-ignore
        [name]: { ...searchData[name], value: value },
      };
    });
  };

  return {
    isSearching,
    isLoading,
    initialLoad,
    isViewing,
    isEditing,
    responseData,
    editData,
    messageTableHeaders,
    sortAscending,
    sortedValue,
    autoCompleteValue,
    autoCompleteOptions,
    messageTypes,
    searchData,
    availableProviders,
    initialResponseData,
    displayingFilteredData,
    userSearched,
    setUserSearch,
    setSearchData,
    handleSorting,
    handleRowSelection,
    handleGoBack,
    handleChange,
    setAutoCompleteValue,
    onAutoCompleteSelect,
    handleSubmit,
    setIsSearching,
    handleFilterInputChange,
    setResponseData,
    handleResetSearch,
    onAutoCompleteSearch,
  };
};
