import { useEffect, useState } from "react";
import { format, addDays } from "date-fns";

import { formatDateToAPI, getFriendlyTimezoneName } from "../../../utils";
import {
  getBusinessPartnersAPI,
  getDataProvidersAPI,
  getVehicleMessagesAPI,
} from "../../../axios";
import { useIsMobile, useReport } from "../../../hooks";
import {
  ConnectOneButton,
  ConnectOneDropdown,
  ConnectOneModal,
  ConnectOneTable,
  ConnectOneTableControls,
} from "../../../components";
import { vehicleCommunicationReportDescription } from "./VehicleCommunicationsReportDescription";

import "./VehicleCommunications.scss";

export const VehicleCommunicationsReport = () => {
  const isMobile = useIsMobile();
  const [isViewingAdvancedSearch, setIsViewingAdvancedSearch] = useState(false);
  const {
    reportData,
    currentPageIndex,
    totalPages,
    dataToDisplay,
    isLoading,
    headers,
    exportData,
    sortAscending,
    sortedValue,
    exportFileName,
    setIsLoading,
    setReportData,
    setCurrentPageIndex,
    handleSorting,
  } = useReport({ isMobile, reportType: "vehicleMessages" });

  const [filterValues, setFilterValues] = useState({
    vin: {
      type: "autocomplete",
      label: "Vin",
      value: "",
      placeholderText: "Select a VIN",
    },
    dateFrom: {
      type: "datetime-local",
      label: "Date From",
      value: `${format(addDays(new Date(), -7), "yyyy-MM-dd")}T00:00`,
    },
    dateTo: {
      type: "datetime-local",
      label: "Date To",
      value: `${format(new Date(), "yyyy-MM-dd")}T23:59`,
    },
  });

  const emptyAdvancedFilterValues = {
    provider: {
      type: "dropdown",
      label: "Provider",
      value: "",
      options: [] as any,
    },
    businessPartner: {
      type: "dropdown",
      label: "Business Partner",
      value: "",
      options: [] as any,
    },
  };

  const [advancedFilterValues, setAdvancedFilterValues] = useState<{
    [key: string]: any;
  }>({ ...emptyAdvancedFilterValues });

  const submitButtonEnabled = Object.values(filterValues).some(
    (filterValue: any) => !!filterValue.value
  );

  const [dataProviders, setDataProviders] = useState<any[]>([]);
  const [, setBusinessPartners] = useState<any[]>([]);

  // const [businessPartners, setBusinessPartners] = useState<any[]>([]);

  const getDataProviders = async () => {
    try {
      const dataProvidersOptions = await getDataProvidersAPI();
      setDataProviders(dataProvidersOptions);
    } catch (err) {
      console.log(err);
    }
  };
  const getBusinessPartners = async () => {
    try {
      const dataBusinessPartnerOptions = await getBusinessPartnersAPI();
      const businessPartnerOptions = dataBusinessPartnerOptions.map(
        (partner: any) => ({
          label: partner.businessPartnerName,
          value: partner.businessPartnerName,
        })
      );
      setBusinessPartners(businessPartnerOptions);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getDataProviders();
    getBusinessPartners();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getTableData = async () => {
    setIsLoading(true);
    setCurrentPageIndex(1);
    try {
      const res: any = await getVehicleMessagesAPI({
        vin: filterValues.vin.value,
        dateFrom: formatDateToAPI(filterValues.dateFrom.value),
        dateTo: formatDateToAPI(filterValues.dateTo.value),
        provider: advancedFilterValues.provider.value,
        businessPartnerName: advancedFilterValues.businessPartner.value,
      });

      const shapedReportData = res.map((message: any) => {
        // Transform the date to a user-friendly format matching other data
        const requestDateTime = message?.requestTime
          ? formatDateToAPI(message?.requestTime)
          : null;

        return {
          ...message,
          requestDateTime,
          timeZoneName: getFriendlyTimezoneName(
            message.timeZone,
            message.localTimeStamp,
            "long"
          ),
        };
      });

      setReportData(shapedReportData);
      setIsLoading(false);
    } catch (err) {
      setReportData([]);
      console.log(err);
    }
  };

  const handleAdvancedSearchChange = (e: any) => {
    const { name, value } = e.target;

    setAdvancedFilterValues((prevValues: any) => ({
      ...prevValues,
      [name]: {
        ...prevValues[name],
        value: value,
      },
    }));
  };

  const getAdvancedSearchTableData = async () => {
    try {
      setIsLoading(true);
      setCurrentPageIndex(1);
      const res: any = await getVehicleMessagesAPI({
        vin: filterValues.vin.value,
        dateFrom: formatDateToAPI(filterValues.dateFrom.value),
        dateTo: formatDateToAPI(filterValues.dateTo.value),
        provider: advancedFilterValues.provider.value,
        businessPartnerName: advancedFilterValues.businessPartner.value,
      });

      const shapedReportData = res.map((message: any) => {
        // Transform the date to a user-friendly format matching other data
        const requestDateTime = message?.requestTime
          ? formatDateToAPI(message?.requestTime)
          : null;

        return {
          ...message,
          requestDateTime,
          timeZoneName: getFriendlyTimezoneName(
            message.timeZone,
            message.localTimeStamp,
            "long"
          ),
        };
      });

      setReportData(shapedReportData);
    } catch (err) {
      setReportData([] as any);
      setIsViewingAdvancedSearch(false);
      console.log(err);
    }
    setAdvancedFilterValues({ ...emptyAdvancedFilterValues });
    setIsLoading(false);
    setIsViewingAdvancedSearch(false);
  };

  return (
    <>
      <div className="vehicle-messages-container vehicle-communications-report-description">
        <ConnectOneTableControls
          fullData={reportData}
          dataIsLoading={isLoading}
          totalPages={totalPages}
          currentPageIndex={currentPageIndex}
          setCurrentPageIndex={setCurrentPageIndex}
          filterValues={filterValues}
          setFilterValues={setFilterValues}
          handleFilterSubmit={getTableData}
          submitButtonDisabled={!submitButtonEnabled}
          fileName={exportFileName("VEHICLE_MESSAGES", filterValues)}
          exportData={exportData}
          reportTitle="Vehicle Message Report"
          reportDescription={vehicleCommunicationReportDescription()}
          handleAdvancedSearch={() =>
            setIsViewingAdvancedSearch(!isViewingAdvancedSearch)
          }
        />
      </div>
      <ConnectOneTable
        data={dataToDisplay}
        headers={headers}
        caption="Vehicle Messages"
        sortGridData={handleSorting}
        sortAscending={sortAscending}
        sortedValue={sortedValue}
      />

      {isViewingAdvancedSearch && (
        <ConnectOneModal
          title={"Advanced Search"}
          handleClose={() => setIsViewingAdvancedSearch(false)}>
          <div className="advanced-search-container">
            <h2>Data Providers</h2>
            <div className="inputs-container">
              <ConnectOneDropdown
                handleChange={handleAdvancedSearchChange}
                label={"Data Provider"}
                name="provider"
                placeholder="Select a data provider"
                value={advancedFilterValues.provider.value}
                options={[{ label: "Any", value: "" }, ...dataProviders]}
              />
              {/* <ConnectOneDropdown
                handleChange={handleAdvancedSearchChange}
                label={"Business Partner"}
                name="businessPartner"
                placeholder="Select a business partner"
                value={advancedFilterValues.businessPartner.value}
                options={[{ label: "Any", value: "" }, ...businessPartners]}
              /> */}
            </div>
          </div>
          <div className="advanced-search-submit">
            <ConnectOneButton
              label={"Search"}
              handleClick={getAdvancedSearchTableData}
              disabled={!submitButtonEnabled}
              isLoading={isLoading}
              toolTipMessage={
                !submitButtonEnabled ? "Agency is required to search" : ""
              }
            />
          </div>
        </ConnectOneModal>
      )}
    </>
  );
};
