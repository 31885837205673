import axios from "axios";
import { getUserAccessToken, refreshCognitoToken } from "../../utils";

export const getMessageButtonActionsAPI = async () => {
  try {
    const accessToken = await getUserAccessToken();
    await refreshCognitoToken();
    const res = await axios({
      url: `${process.env.REACT_APP_API_URL}/systemSettings/MessageButtonActions`,
      method: "GET",
      headers: {
        Authorization: accessToken,
      },
    });
    return res.data.data;
  } catch (err) {
    console.log("Get Message Button Actions Error: ", err.message);
  }
};
