import axios from "axios";

import { refreshCognitoToken, getUserAccessToken } from "../../utils";

export const getTollTransactionReconcileAPI = async (params: any) => {
  try {
    const accessToken = await getUserAccessToken();
    await refreshCognitoToken();
    const response = await axios({
      url: `${process.env.REACT_APP_API_URL}/tollReconcileReport`,
      method: "POST",
      headers: {
        Authorization: accessToken,
      },
      data: params,
    });
    return response.data;
  } catch (err) {
    console.log("Get Toll Reconcile Report Err: ", err.message);
    throw err;
  }
};
