// import { useEffect, useState } from "react";
import { useEffect } from "react";

import { MessageEditForm } from "./MessageEditForm";
import { messagesFilterState } from "../../../assets";
import { useMessageManagement } from "../../../hooks";
import { MessagesAdvancedSearch } from "./MessagesAdvancedSearch";
import {
  ConnectOneClickableTable,
  ConnectOneLoader,
} from "../../../components";
// import { getBusinessPartnersAPI } from "../../../axios";

import "./Messages.scss";

export const AdminControlMessages = ({ user }: { user?: any }) => {
  const {
    isSearching,
    isLoading,
    initialLoad,
    isViewing,
    isEditing,
    responseData,
    editData,
    messageTableHeaders,
    sortAscending,
    sortedValue,
    autoCompleteValue,
    autoCompleteOptions,
    messageTypes,
    searchData,
    availableProviders,
    initialResponseData,
    displayingFilteredData,
    userSearched,
    setUserSearch,
    setIsSearching,
    handleSorting,
    handleRowSelection,
    handleGoBack,
    handleChange,
    setAutoCompleteValue,
    onAutoCompleteSelect,
    handleSubmit,
    handleFilterInputChange,
    setResponseData,
    handleResetSearch,
    onAutoCompleteSearch,
  } = useMessageManagement({
    filterState: messagesFilterState,
    user: user,
  });
  // const [businessPartnersList, setBusinessPartnersList] = useState({});

  useEffect(() => {
    if (availableProviders) {
      searchData.provider.options = availableProviders.map(
        (provider: string) => {
          return { label: provider, value: provider };
        }
      );
    }
  }, [availableProviders, searchData.provider]);

  const handleFilterData = ({
    resetFilters = false,
  }: {
    resetFilters?: boolean;
  }) => {
    const filterParams: any = {};

    if (resetFilters) {
      setAutoCompleteValue("");
      setResponseData(initialResponseData);
      setUserSearch(false);
      return;
    }

    if (!resetFilters) {
      Object.entries(searchData).forEach(
        ([key, value]: [key: string, value: any]) => {
          if (value.value) {
            filterParams[key] = value.value;
          }
        }
      );
    }

    const filteredData = [...initialResponseData].filter((message: any) => {
      const { name, provider, type, businessPartner } = filterParams;
      return (
        (name === undefined ||
          message.displayName.toLowerCase().includes(name.toLowerCase())) &&
        (provider === undefined || message.provider === provider) &&
        (type === undefined || message.messageType === type) &&
        (businessPartner === undefined ||
          message.businessPartner === businessPartner)
      );
    });

    setResponseData(filteredData);
    setIsSearching(false);
  };

  // const fetchBusinessPartners = async () => {
  //   try {
  //     const listBusinessPartners = await getBusinessPartnersAPI();
  //     setBusinessPartnersList({
  //       ...businessPartnersList,
  //       options: [
  //         ...listBusinessPartners
  //           .filter((partner: any) => partner.businessPartnerName)
  //           .map((partner: any) => ({
  //             label: partner.businessPartnerName,
  //             value: partner.businessPartnerName,
  //           })),
  //       ],
  //     });
  //   } catch (error) {
  //     console.error("Error getting business partners", error);
  //   }
  // };

  //Get list of buttons actions and business partners from DB
  // useEffect(() => {
  //   fetchBusinessPartners();
  //   // eslint-disable-next-line
  // }, []);

  return (
    <div className="messages-page-container">
      {initialLoad && <ConnectOneLoader />}

      {isViewing && (
        <ConnectOneClickableTable
          data={responseData}
          label={"In Vehicle Messages"}
          headers={messageTableHeaders}
          sortGridData={handleSorting}
          exportData={[]}
          handleRowSelection={handleRowSelection}
          sortedValue={sortedValue}
          sortAscending={sortAscending}
          autoCompleteValue={autoCompleteValue}
          setAutoCompleteValue={setAutoCompleteValue}
          autoCompleteOptions={autoCompleteOptions}
          onAutoCompleteSelect={onAutoCompleteSelect}
          autoCompleteLabel={"Provider"}
          autCompletePlaceholder={"Select a Provider"}
          tableBodyHeight="236px"
          description="List of all in vehicle message and their properties."
          handleSearch={onAutoCompleteSearch}
          advancedSearchLabel={"Advanced"}
          handleAdvancedSearch={() => setIsSearching(true)}
          resetButtonLabel={"Clear Search"}
          handleReset={
            displayingFilteredData || userSearched
              ? () => {
                  handleResetSearch();
                  handleFilterData({ resetFilters: true });
                }
              : undefined
          }
          searchButtonDisabled={!autoCompleteValue}
        />
      )}

      {isSearching && (
        <MessagesAdvancedSearch
          searchData={searchData}
          isLoading={isLoading}
          handleChange={handleFilterInputChange}
          handleClose={() => setIsSearching(false)}
          handleSubmit={handleFilterData}
          // businessPartnersList={businessPartnersList}
        />
      )}

      {isEditing && (
        <>
          <span className="co-modal-backdrop" />
          <MessageEditForm
            data={editData}
            messageTypes={messageTypes}
            handleChange={handleChange}
            handleGoBack={handleGoBack}
            handleSubmit={handleSubmit}
            isLoading={isLoading}
            // businessPartnersList={businessPartnersList}
            // setBusinessPartnersList={setBusinessPartnersList}
          />
        </>
      )}
    </div>
  );
};
