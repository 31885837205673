import axios from "axios";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import { getUserAccessToken } from "../utils";

type LocationStatus = {
  _id: string;
  name: string;
};

export interface LocationStatusesState {
  isLoading: boolean;
  hasError: boolean;
  locationStatusesData: LocationStatus[];
}

const initialState: LocationStatusesState = {
  isLoading: false,
  hasError: false,
  locationStatusesData: [],
};

export const getLocationStatuses = createAsyncThunk(
  "locationStatuses/getLocationStatuses",
  async () => {
    try {
      const accessToken = await getUserAccessToken();
      const response = await axios({
        url: `${process.env.REACT_APP_API_URL}/systemSettings/LocationStatuses`,
        method: "GET",
        headers: {
          Authorization: accessToken,
        },
      });
      return response.data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  }
);

export const locationStatusesSlice = createSlice({
  name: "locationStatusesSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getLocationStatuses.pending, (state, action) => {
        state.isLoading = true;
        state.hasError = false;
      })
      .addCase(getLocationStatuses.fulfilled, (state, action) => {
        state.isLoading = false;
        state.hasError = false;
        state.locationStatusesData = action?.payload?.data
          ?.map((e: any) => ({
            ...e,
            label: e.name,
            value: e._id,
          }))
          .sort((a: any, b: any) =>
            a.name?.toLowerCase()?.localeCompare(b.name?.toLowerCase())
          );
      })
      .addCase(getLocationStatuses.rejected, (state, action) => {
        state.hasError = true;
        state.isLoading = false;
      });
  },
});

export default locationStatusesSlice.reducer;
